import NumberFormat from 'react-number-format';

import { CRTable, Div, H4 } from 'components';
import { getUnitOfMeasureShortCut } from 'services/inventory';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';
import moment from 'moment';

const ListInventory = ({ items, totalCostOfItems }) => {
  const { t } = useTranslation();

  // Format the date
  const formattedString = moment(new Date()).format('ddd DD-MM-YYYY');
  return (
    <Fragment>
      <Div display="flex" ml="10px" mb="20px">
        <H4 mr="10px">Date:</H4>
        <Div mt="6.5px">{formattedString}</Div>
      </Div>
      <CRTable autoHeight data={items}>
        <CRTable.CRColumn width={50}>
          <CRTable.CRHeaderCell>{t('number')}</CRTable.CRHeaderCell>
          <CRTable.CRCell>
            {({ name }, index) => (
              <CRTable.CRCellStyled>{index + 1}</CRTable.CRCellStyled>
            )}
          </CRTable.CRCell>
        </CRTable.CRColumn>
        <CRTable.CRColumn width={200}>
          <CRTable.CRHeaderCell>{t('name')}</CRTable.CRHeaderCell>
          <CRTable.CRCell>
            {({ name }) => <CRTable.CRCellStyled>{name}</CRTable.CRCellStyled>}
          </CRTable.CRCell>
        </CRTable.CRColumn>

        <CRTable.CRColumn width={200}>
          <CRTable.CRHeaderCell>{t('numberOfBoxes')}</CRTable.CRHeaderCell>
          <CRTable.CRCell dataKey="amount" semiBold>
            {({ amount }) => (
              <CRTable.CRCellStyled semiBold>
                <NumberFormat
                  value={amount}
                  displayType="text"
                  thousandSeparator
                />
              </CRTable.CRCellStyled>
            )}
          </CRTable.CRCell>
        </CRTable.CRColumn>

        <CRTable.CRColumn width={200}>
          <CRTable.CRHeaderCell>{t('uintsQuantity')}</CRTable.CRHeaderCell>
          <CRTable.CRCell>
            {({ quantity, item }) => (
              <CRTable.CRCellStyled semiBold>
                <NumberFormat
                  value={quantity}
                  displayType="text"
                  thousandSeparator
                />
                <span display="inline" ml={2}>
                  {getUnitOfMeasureShortCut(item.unitOfMeasure)}
                </span>
              </CRTable.CRCellStyled>
            )}
          </CRTable.CRCell>
        </CRTable.CRColumn>
        <CRTable.CRColumn flexGrow={1}>
          <CRTable.CRHeaderCell>{t('totalCostOfUnits')}</CRTable.CRHeaderCell>
          <CRTable.CRCell>
            {({ totalCost }) => (
              <CRTable.CRCellStyled>{totalCost}</CRTable.CRCellStyled>
            )}
          </CRTable.CRCell>
        </CRTable.CRColumn>
        <CRTable.CRColumn width={200}>
          <CRTable.CRHeaderCell>{t('branch')}</CRTable.CRHeaderCell>
          <CRTable.CRCell>
            {({ branch }) => (
              <CRTable.CRCellStyled>
                {branch?.name ? branch?.name : 'Organization warehouse'}
              </CRTable.CRCellStyled>
            )}
          </CRTable.CRCell>
        </CRTable.CRColumn>
      </CRTable>
      <div className="bg-black p-5 font-bold text-white w-full">
        Total Cost : {totalCostOfItems}
      </div>{' '}
    </Fragment>
  );
};

export default ListInventory;
