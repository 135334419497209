import React, { useEffect, useMemo } from 'react';
import { Form } from 'rsuite';
import { CRSelectInput } from 'components';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { useAppSelector, useAppDispatch } from 'redux-store/hooks';
import {
  selectSelectedBranch,
  selectSelectedSpecialty,
  selectSelectedDoctor,
  setSelectedSpecialty,
  setSelectedDoctor,
  selectSelectedAllAppBranch,
  selectSelectedAllAppSpecialty,
  selectSelectedAllAppDoctor,
  setSelectedAllAppSpecialty,
  setSelectedAllAppDoctor,
  setSelectedBranch,
  setSelectedAllAppBranch,
} from 'features/root/rootSlice';
import {
  SELECTED_BRANCH,
  SELECTED_BRANCH_ALL,
  SELECTED_DOCTOR,
  SELECTED_DOCTOR_ALL,
  SELECTED_SPECIALTY,
  SELECTED_SPECIALTY_ALL,
} from 'utils/constants';

const containThisBranch = (branches, branchId) => {
  const branchesIds = branches.reduce((acc, { id }) => [...acc, id], []);
  if (branchesIds.includes(branchId)) {
    return true;
  } else {
    return false;
  }
};
const getAccessfulBranch = branches => {
  if (branches.length > 0) {
    return branches[0]?.id;
  } else {
    return null;
  }
};
function AppointmentsFilter({
  formValue,
  onChange,
  branches,
  formClassName,
  todayApp,
  cleanable,
  notAllowUser,
  notAllowSpecialty,
  allApp,
}) {
  const dispatch = useAppDispatch();
  const branchId = useAppSelector(selectSelectedBranch);
  const selectedSpecialty = useAppSelector(selectSelectedSpecialty);
  const selectedDoctor = useAppSelector(selectSelectedDoctor);
  const branchIdAllApp = useAppSelector(selectSelectedAllAppBranch);
  const selectedSpecialtyAllApp = useAppSelector(selectSelectedAllAppSpecialty);
  const selectedDoctorAllApp = useAppSelector(selectSelectedAllAppDoctor);
  const { t } = useTranslation();

  // When Global branch changed
  useEffect(() => {
    if (
      containThisBranch(branches, branchId) ||
      containThisBranch(branches, branchIdAllApp)
    ) {
      if (todayApp) {
        onChange(prev => ({
          ...prev,
          branch: branchId,
          specialty: selectedSpecialty,
          doctor: selectedDoctor,
        }));
      } else if (allApp) {
        onChange(prev => ({
          ...prev,
          branch: branchIdAllApp,
          specialty: selectedSpecialtyAllApp,
          doctor: selectedDoctorAllApp,
        }));
      } else {
        onChange(prev => ({ ...prev, branch: branchId }));
      }
    }
  }, [
    branchId,
    onChange,
    selectedSpecialty,
    selectedDoctor,
    todayApp,
    branches,
    branchIdAllApp,
    selectedSpecialtyAllApp,
    selectedDoctorAllApp,
    allApp,
  ]);

  const specialties = useMemo(
    () =>
      R.pipe(
        R.find(R.propEq('id', formValue.branch)),
        R.propOr([], 'specialties')
      )(branches),
    [branches, formValue.branch]
  );

  const doctors = useMemo(
    () =>
      R.pipe(
        R.find(R.propEq('id', formValue.specialty)),
        R.propOr([], 'doctors')
      )(specialties),
    [formValue.specialty, specialties]
  );

  return (
    <Form
      formValue={formValue}
      onChange={onChange}
      fluid
      className={`grid grid-cols-2 sm:grid-cols-3 gap-4 mb-10 ${formClassName}`}
    >
      <div className="flex-1">
        <CRSelectInput
          name="branch"
          label={t('branch')}
          placeholder={t('select')}
          data={branches}
          block
          cleanable={!cleanable}
          onChange={value => {
            if (value) {
              if (todayApp) {
                dispatch(setSelectedBranch(value));
                localStorage.setItem(SELECTED_BRANCH, value);
              } else if (allApp) {
                dispatch(setSelectedAllAppBranch(value));
                localStorage.setItem(SELECTED_BRANCH_ALL, value);
              } else {
                onChange({ ...formValue, branch: value });
              }
            }
          }}
        />
      </div>
      {!notAllowSpecialty && (
        <div className="flex-1">
          <CRSelectInput
            name="specialty"
            label={t('specialty')}
            placeholder={t('select')}
            block
            data={specialties}
            onChange={value => {
              if (value) {
                if (todayApp) {
                  dispatch(setSelectedSpecialty(value));
                  localStorage.setItem(SELECTED_SPECIALTY, value);
                } else if (allApp) {
                  dispatch(setSelectedAllAppSpecialty(value));
                  localStorage.setItem(SELECTED_SPECIALTY_ALL, value);
                } else {
                  onChange({ ...formValue, specialty: value });
                }
              }
            }}
            onClean={() => {
              if (todayApp) {
                dispatch(setSelectedSpecialty(null));
                localStorage.removeItem(SELECTED_SPECIALTY);
              }
              if (allApp) {
                dispatch(setSelectedAllAppSpecialty(null));
                localStorage.removeItem(SELECTED_SPECIALTY_ALL);
              }
            }}
          />
        </div>
      )}
      {!notAllowUser && (
        <div className="flex-1">
          <CRSelectInput
            name="doctor"
            label={t('user')}
            block
            data={doctors}
            placeholder={t('select')}
            onChange={value => {
              if (value) {
                if (todayApp) {
                  dispatch(setSelectedDoctor(value));
                  localStorage.setItem(SELECTED_DOCTOR, value);
                } else if (allApp) {
                  dispatch(setSelectedAllAppDoctor(value));
                  localStorage.setItem(SELECTED_DOCTOR_ALL, value);
                } else {
                  onChange({ ...formValue, doctor: value });
                }
              }
            }}
            onClean={() => {
              if (todayApp) {
                dispatch(setSelectedDoctor(null));
                localStorage.removeItem(SELECTED_DOCTOR);
              }
              if (allApp) {
                dispatch(setSelectedAllAppDoctor(null));
                localStorage.removeItem(SELECTED_DOCTOR_ALL);
              }
            }}
          />
        </div>
      )}
    </Form>
  );
}

export default AppointmentsFilter;
