export const TRANSLATIONS_AR = {
  appointments: 'المواعيد',
  patients: 'المرضي',
  calendar: 'التقويم',
  todayAppointments: 'مواعيد اليوم',
  reports: 'التقارير',
  surgeries: 'العمليات الجراحيه',
  reportsPrintout: 'طباعه التقارير',
  sales: 'المبيعات',
  courses: 'الكورسات',
  permissions: 'الصلاحيات',
  print: 'طباعه',
  mainAppointments: 'المواعيد الرئيسيه',
  waitingAppointments: 'مواعيد الانتظار',
  user: 'المستخدم',
  branch: 'الفرع',
  specialty: 'التخصص',
  nameOrCode: 'الاسم او الكود ',
  phoneNo: 'رقم التليفون',
  phoneNoTwo: 'رقم التليفون الثاني',
  reference: 'الإِشارة',
  area: 'المكان',
  edit: 'تعديل',
  accounting: 'حسابات الكاش',
  statistics: 'الاحصائيات',
  patient: 'اسم المريض',
  date: 'التاريخ',
  time: 'الوقت',
  newAppointment: 'حجز جديد',
  addToWaitingList: 'إضافة إلي القائمه المنتظره',
  ok: 'إنشاء',
  cancel: 'إلغاء',
  createNewPatient: 'إضافة مريض جديد',
  completedAppointments: 'المواعيد المكتمله',
  doctor: 'الدكتور',
  type: 'النوع',
  actions: 'العمليات',
  archive: 'انهاء',
  acc: 'يحاسب',
  duplicates: 'تكرار',
  notes: 'ملاحظات',
  status: 'الحاله',
  range: 'التاريخ من - الي',
  nameOrPhoneNo: 'اسم المريض / رقم التليفون',
  socialReport: 'تقرير السوشيال مديا',
  areaReport: 'تقرير المكان',
  membershipType: 'نوع العضويه',
  code: 'الكود',
  newPatient: 'إضافة مريض جديد',
  age: 'السن',
  birthOfDate: 'تاريخ الميلاد',
  bankAndInsuranceAccounting: 'حسابات البنوك وشركات التأمين',
  bankAccounting: 'حسابات البنوك',
  insuranceAccounting: 'حسابات شركات التامين',
  banking: 'البنوك',
  insurance: 'التامين',
  today: 'اليوم',
  week: 'الاسبوع',
  month: 'الشهر',
  quarter: 'ربع السنوي',
  year: 'السنه',
  showingFor: 'من - إلي',
  bank: 'البنك',
  details: 'التفاصيل',
  summary: 'الملخص',
  revenueName: 'أسم اﻹيراد',
  revenues: 'اﻹيرادات',
  name: 'الاسم',
  revenueAmount: 'المبلغ',
  expenseType: 'نوع المصروفات',
  expenses: 'المصروفات',
  profit: 'الدخل',
  newRevenue: 'إضافة إيراد جديد',
  newExpense: 'إضافة مصروف جديد',
  invoiceNo: 'رقم الفاتوره',
  bankingRevenues: 'إيرادات البنوك',
  bankingExpenses: 'مصروفات البنوك',
  company: 'الشركه',
  insuranceRevenues: 'إيرادات التامين',
  companyName: 'اسم الشركه',
  editRevenue: 'تعديل اﻹيراد',
  editExpense: 'تعديل المصروف',
  addNewBankTransaction: 'إضافة معامله بنكيه جديده',
  editBankTransaction: 'تعديل المعامله البنكيه',
  deleteBankTransaction: 'حذف المعامله البنكيه',
  deleteBankMessage: 'هل انت متاكد من حذف هذه المعامله ؟',
  patientsSurgeries: 'العمليات الجراحيه',
  surgery: 'عمليه جراحيه',
  hospital: 'المستشفي',
  anesthesiaType: 'نوع التخدير',
  anesthesiaDoctorName: 'اسم دكتور التخدير',
  fees: 'الرسوم',
  othersFees: 'رسوم اخري',
  hospitalFees: 'رسوم المستشفي',
  assistantFees: 'رسوم مساعد الدكتور',
  anesthesiaFees: 'رسوم التخدير',
  doctorFees: 'رسوم الدكتور',
  timeOfAdmission: 'وقت قبول الحاله',
  others: 'اخري',
  addNewSurgery: 'إضافة عمليه جراحيه جديده',
  editSurgery: 'تعديل العمليه الجراحيه',
  patientReports: 'التقارير',
  contextData: 'نوع الداتا',
  body: 'التقرير',
  appointment: 'الكشف',
  addNewSales: 'بيع منتج جديد',
  item: 'المنتج',
  creator: 'البائع',
  number: 'الترتيب',
  price: 'السعر',
  quantity: 'الكميه',
  totalPrice: 'المبلغ الكلي',
  add: 'إضافة',
  editSales: 'تعديل بيع جديد',
  deleteSalesMessage: 'هل انت متاكد من حذف هذا المنتج ؟',
  course: 'الكورس',
  courseStatus: 'حاله الكورس',
  allCourses: 'كل الكورسات',
  courseName: 'اسم الكورس',
  coursePrice: 'سعر الكورس',
  courseUnpaid: 'المتبقي من الكورس',
  admin: 'الادمن',
  branches: 'الفروع',
  specialties: 'التخصصات',
  users: 'المستخدمين',
  assign: 'اسناد',
  rolePermission: 'الاذونات',
  newBranch: 'فرع جديد',
  addSpecialty: 'إضافة تخصص للفرع',
  doctors: 'الدكاتره',
  newSpecialty: 'تخصص جديد',
  addDoctor: 'إضافة دكتور لتخصص',
  newUser: 'إضافة مستخدم جديد',
  position: 'الدكتور',
  email: 'البريد الإلكتروني',
  password: 'الرقم السري',
  viewsAllowedToUser: 'الاقسام المسوح بها للمستخدم',
  editUser: 'تعديل المستخدم',
  role: 'اسم الصلاحيات',
  change: 'تغير',
  address: 'العنوان', ///
  configurations: 'اﻹعدادات',
  staticInfo: 'المعلومات الثابته لتطبيق',
  payroll: 'نظام المرتبات',
  snippets: 'اﻹختصارات',
  inventory: 'المخزن',
  logout: 'تسجيل الخروج', ///
  save: 'حفظ',
  pointsControl: 'نظام النقط الهديه',
  theNumberOfPointsToGetCoupon: 'عدد النقط للحصول علي كوبون',
  couponValue: 'قيمه الكوبون',
  pulsesControl: 'نظام النبضات',
  before: 'قبل',
  after: 'بعد',
  pageSetupControl: 'إعدادات الصفحه (بالسنتيمتر)',
  enableInvoiceCounter: 'تمكين عداد الفاتورة',
  top: 'الاعلي',
  bottom: 'الاسفل',
  right: 'اليمين',
  left: 'اليسار', //
  hospitals: 'المستشفيات',
  medicineDefinition: 'تعريف الدواء',
  labDefinition: 'تعريف اسماء التحاليل',
  labCategory: ' اسماء نوعيه التحاليل',
  imageDefinition: ' اسماء الاشعه',
  imageCategory: ' اسماء نوع الاشعه',
  timing: 'تعربف مصطلحات الوقت',
  coursesAndPackages: 'الكورسات والعروض',
  salesDefinition: 'تعريف المبيعات',
  bankDefinition: 'تعريف اسماء البنوك',
  insuranceCompanies: 'تعريف شركات التامين',
  insuranceCompaniesSessions: 'أسماء جلسات شركات التامين',
  expensesTypes: 'انواع المصروفات',
  sessionsDefinition: 'الكشوفات',
  dentalDiagnosis: 'تشخيص الاسنان',
  faceMaterials: 'احتياجات عمليات الوجه',
  patientReport: 'اسماء التقارير',
  appointmentType: 'نوعيه الكشوف', //
  addNewHospital: 'إضافة مستشفي جديده',
  editHospital: 'تعديل مستشفي',
  level: 'المستوي',
  addNewMedicine: 'إضافة دواء جديد',
  editMedicine: 'تعديل دواء',
  concentration: 'التركيز',
  medicineForm: 'التكوين',
  addNewLabDefinition: 'إضافة تحليل جديد',
  editLabDefinition: 'تعديل تحليل',
  category: 'الفئه',
  addNewLabCategory: 'إضافة فئه تحليل جديده',
  editLabCategory: 'تعديل فئه تحليل ',
  lab: 'تحليل',
  image: 'اشعه',
  addNewImageDefinition: 'إضافة اشعه جديده',
  editImageDefinition: 'تعديل اشعه جديده',
  delete: 'حذف',
  editImageCategory: 'تعديل فئه الاشعه',
  addNewTiming: 'إضافة مصطلح وقت جديد',
  editTiming: 'تعديل مصطلح وقت',
  englishPrintValue: 'مصطلح الوقت بالغه الانجليزيه',
  arabicPrintValue: 'مصطلخ الوقت باللغه العربيه',
  addNewCourse: 'أضافه كورس جديد',
  editNewCourse: 'تعديل الكورس',
  numberofSessionsUnits: 'عدد الجلسات/الوحدات',
  messureOfUnits: 'وحده قياس الوحدات',
  units: 'الوحدات',
  addNewSalesDefinition: 'إضافة منتج جديد',
  editSalesQuantity: 'تعديل كميه المنتج',
  editSalesDefinition: 'تعديل المنتج',
  addSalesQuantity: 'إضافة كميات للمنتج',
  cost: 'التكلفه',
  totalQauantity: 'الكميه الكليه',
  addNewBank: 'إضافة بنك جديد',
  editBank: 'تعديل البنك',
  addNewCompany: 'إضافة شركه جديده',
  editCompany: 'تعديل الشركه',
  addNewCompanySession: 'إضافة جلسه شركه جديده',
  editCompanySession: 'تعديل جلسه شركه',
  addNewExpenseType: 'إضافة نوع مصروفات جديد',
  editExpenseType: 'تعديل نوع المصروفات',
  addNewSessionDefinition: 'إضافة جلسه جديده',
  editSessionDefinition: 'تعديل الكشف',
  sessionName: 'اسم الكشف',
  duration: 'المده',
  addNewDentalDiagnosis: 'إضافة تشخيص اسنان جديد',
  editDentalDiagnosis: 'تعديل تشخيص الاسنان',
  addNewFaceMaterial: 'إضافة مواد الوجه ',
  editFaceMaterial: 'تعديل مواد الوجه',
  addNewPatientReport: 'إضافة تقرير المريض',
  editPatientReport: 'تعديل تقرير المريض',
  addNewAppointmentType: 'إضافة نوع كشف جديد',
  editAppointmentType: 'تعديل نوع الكشف',
  urgent: 'مستعجل', //
  checkNo: 'رقم الشيك',
  courseType: 'نوع الكورس',
  supplierAccount: 'حساب الموردين',
  addNewCourseType: 'إضافة نوع كورس جديد',
  editNewCourseType: 'تعديل نوع الكورس',
  addNewSupplierAccount: 'إضافة حساب موردين جديد',
  editSupplierAccount: 'تعديل حساب موردين', //
  payrollReports: 'تقارير دفع المرتبات',
  payPayslips: 'دفع المرتبات',
  addNewUser: 'إضافة مستخدم جديد',
  addAdvance: 'إضافة سلفه',
  addIncentives: 'إضافة حوافز',
  addCommission: 'إضافة عموله',
  addDeduction: 'إضافة مستقطع مرتبي',
  deletePayrollUser: 'حذف مستخدم من المرتبات',
  salary: 'ألمرتب',
  reason: 'السبب',
  selectOneOption: 'أختار اختيار واحد',
  numberOfHourse: 'عدد الساعات',
  priceOfHours: 'سعر الساعات',
  basicSalary: 'المرتب الاساسي',
  commission: 'العموله',
  advance: 'ألسلفه',
  incentive: 'الحوافز',
  deduction: 'المستقطع',
  netSalary: 'المرتب الكلي',
  total: 'الكلي',
  openDetails: 'تفاصيل اكتر', //
  information: 'معلومات',
  expand: 'توضيح اكثر',
  session: 'الكشف',
  tableView: 'اظهار الداتا علي شكل جدول',
  pendingLab: 'تحليل فعال',
  historyLab: 'تحليل منتهي',
  pendingImage: 'اشعه فعال',
  historyImage: 'اشعه منتهي',
  value: 'القيمه', //
  addNewSnippet: 'أضافه إختصار جديد',
  title: 'العنوان',
  editSnippet: 'تعديل اﻹختصار',
  deleteSnippet: 'حذف اﻹختصار',
  deleteSnippetMessage: 'هل انت متاكد من حذف هذا اﻹختصار ؟', //
  history: 'المرجع',
  itemDefinition: 'تعريف منتجات المخزن',
  inventoryManual: 'استخدام منتجات المخزن',
  unitPrice: 'سعر الوحده',
  addToInventory: 'أضافه منتجات للمخزن',
  items: 'المنتجات',
  barcode: 'باركود',
  addItem: 'أضافه منتج',
  consume: 'استهلاك',
  consumeInventory: 'استهلاك المنتجات',
  patientInfo: 'داتا المريض',
  sessions: 'الكشوفات',
  labs: 'التحاليل',
  images: 'الاشعه',
  sessionsPulses: 'جلسات النبضات',
  dental: 'الاسنان',
  faceOperation: 'قسم عمليات الوجه',
  progress: 'احصائيات',
  patientInformationCreation: 'انشاء معلومات للمريض',
  patientCoupons: 'الكوبونات',
  patientRevenue: 'الدخل ',
  currentAppointment: 'الكشف', //
  inProgressCourses: 'الكورسات الفعاله',
  finishedCourses: 'الكورسات المنتهيه',
  cancelledCourses: 'الكورسات الملغيه',
  noCourses: 'لا يوجد كورسات',
  discount: 'خصم',
  payByVisa: 'دفع بالفيزا',
  paid: 'المدفوع',
  startDate: 'بدايه من ',
  createNewCourse: 'انشاء كورس جديد',
  cancelThisCourse: 'إلغاء هذا الكورس',
  deleteThisCourse: 'حذف الكورس',
  assignDoctor: 'إضافة دكتور',
  pay: 'دفع',
  consumeUnits: 'استهلاك من الوحدات',
  editUnits: 'تعديل الوحدات',
  finish: 'انهاء',
  totalOfUnits: 'العدد الكلي للوحدات',
  consumed: 'المستهلك',
  remaining: 'المتبقي',
  endOfDate: 'انتهاء في',
  courseSession: 'جلسات الكورس',
  coursePaymentHistory: 'مرجع مدفوعات الكورس',
  courseUnitsHistory: 'مرجع وحدات الكورس',
  expiryDate: 'تاريخ انتهاء الصلاحيه',
  remaingValue: 'القيمه المتبقيه',
  noData: 'لايوجد معلومات',
  downloadFile: 'تحميل الملف',
  coursePartName: 'جزئيه الكورس',
  cashPayment: 'الدفع كاش',
  bankPayment: 'الدفع بالفيزا',
  payment: 'المدفوعات',
  consumedUnits: 'الوحدات المستخدمه',
  finishCourseMessage: 'هل انت متاكد من انهاء الكورس ؟',
  deleteCourseMessage: 'هل انت متاكد من حذف الكورس ؟',
  refundMoney: 'الاموال العائده',
  editConsumedUnits: 'تعديل الوحدات المستخدمه',
  addNewUnits: 'أضافه وحدات جديده',
  addNewPayment: 'دفع جديد',
  editHistoryPayment: 'تعديل مدفوعات سابقه',
  assignNewDoctor: 'أضافه دكتور',
  cancelTheCourse: 'إلغاء الكورس',
  finishTheCourse: 'إنهاء الكورس',
  editUnitTransaction: 'تعديل الوحدات السابقه',
  transactions: 'المعاملات',
  couponTransactions: 'معاملات الكوبونات',
  getAllCoupons: 'كل الكوبونات',
  archiveAppointment: 'إجراء العمليه الحسابيه',
  invoice: 'الفاتوره',
  insurancePay: 'الدفع بشركات التامين',
  couponPay: 'الدفع بالكوبونات',
  remainingPay: 'المتبقي من الدفع',
  points: 'النقاط',
  noCouponsExists: 'لايوجد كوبونات',
  payOfRemaining: 'دفع جزء من المتبقي',
  numberOfSessions: 'عدد الجلسات',
  applied: 'اتمام',
  percentagefrom0To100: 'النسبه المئويه من 0 : 100',
  thePayOfTheRemaining: 'الدفع من المتبقي',
  subtotal: 'حاصل الجمع',
  theRemaining: 'المتبقي',
  fixedDoctorFees: 'رسوم ثابته',
  percentageDoctorFees: 'نسبه مئويه من اجمالي الكشف',
  showMedicines: 'رؤيه كل الروشتات',
  printMedicine: 'طباعه الدواء',
  printImages: 'طباعه الاشعه',
  printLabs: 'طباعه التحاليل',
  more: 'توضيح اكثر',
  sex: 'النوع',
  prescription: 'الروشته',
  pictures: 'الصور',
  noPictures: 'لا يوجد صور',
  medicine: 'الدواء',
  profile: 'الملف الشخصي',
  changeProfilePicture: 'تغير صوره الملف الشخصي',
  changePassword: 'تغير كلمه السر',
  currentPassword: 'كلمه السر الحاليه ',
  newPassword: 'كلمه سر جديده',
  completeAppointmentMessage: 'هل انت متاكد من انهاء الحجز ؟',
  addNotes: 'أضافه ملاحظات',
  adjustAppointment: 'تعديل الكشف ',
  adjust: 'تعديل',
  oldDate: 'التاريخ القديم',
  newDate: 'التاريخ الجديد',
  cancelAppointmentMessage: 'هل انت متاكد من الغاء الحجز ؟',
  cancelAppointment: 'الغاء الحجز',
  totalSales: 'مجموع المبيعات',
  totalCost: 'مجموع التكلفه',
  totalPaid: 'مجموع المدفوع',
  totalUnPaid: 'مجموع المتبقي',
  invoiceCount: 'رقم الفاتوره',
  description: 'الوصف',
  allInvoices: 'كل الفواتير',
  checkDueDate: 'تاريخ استحقاق الفاتوره',
  addNewInvoice: 'إضافه فاتوره جديده',
  editPaidTransaction: 'تعديل معامله الدفع',
  changeTheSupplier: 'تغير الشركه',
  payByCheck: 'الدفع بالشيك',
  invoiceName: 'اسم الفاتوره',
  totalAppointments: 'إجمالي عدد الحجوزات',
  totalWaitingList: 'إجمالي عدد الحجوزات المنتظره',
  viewAll: 'تفاصيل اكثر',
  examinationOrFollowup: 'كشف/متابعه',
  timeframe: 'الفتره الزمنيه',
  editPatient: 'تعديل المريض',
  guardiansName: 'اسم ولي الأمر',
  guardiansPhoneNo: 'رقم تليفون ولي الأمر',
  payer: 'صاحب الدفع',
  monthlyReport: 'تقرير شهري',
  select: 'اختار',
  generate: 'انشاء',
  dailyReport: 'تقرير يومي',
  sessionReport: 'تقرير جلسات النبضات',
  totalUnpaidOfCoursesReport: 'تقرير المتبقي من الكورسات',
  show: 'إظهار',
  close: 'غلق',
  statisticalReports: 'تقارير الاحصائيات',
  noOfPatients: 'عدد المرضي',
  loading: 'تحميل...',
  dose: 'عدد المرات',
  required: 'مطلوب',
  require: 'يطلب',
  nextAppointment: 'المعاد القادم ',
  printArabic: 'طباعه بالعربي',
  printEnglish: 'طباعه بالانجليزي',
  noMedicines: 'لا يوجد ادويه',
  nolabs: 'لا يوجد تحاليل',
  changeLanguage: 'تغير اللغه',
  subscriptionType: 'نوع المريض',
  maritalStatus: 'الحالة الزوجية',
  patientLevel: 'المستوي ',
  deleteBranch: 'حذف الفرع',
  views: 'خانات العمل',
  expenseName: 'اسم المصروف',
  visa: 'فيزا',
  cardNo: 'رقم الكارت',
  transferMedicineOrItem: 'إرسال الدواء أو المنتج',
  transfer: 'إرسال',
  pendingItems: 'منتجات قيد الانتظار',
  numberOfBoxes: ' عدد عْلَب الدَّوَاء ',
  uintsQuantity: 'عدد الوحدات',
  noOfUnits: 'عدد الوحدات',
  unitOfMeasure: 'وحدة القياس',
  purchasingPricePerUnit: 'سعر الشراء للوحدة',
  sellingPricePerUnit: 'سعر البيع للوحدة',
  sellingPricePerBox: 'سعر البيع لكل علبه',
  purchasingPricePerBox: 'سعر الشراء لكل علبه',
  sell: 'يبيع',
  sellItem: 'يبيع منتج',
  noOfUnitsPerBox: 'عدد الوحدات للعلبه',
  pricePerBox: ' سعر العلبه',
  pricePerUnit: 'سعر الوحده',
  salesContainer: 'حاوية البيع',
  salesHistory: 'سِجل المبيعات',
  editSale: 'تصحيح سعر المنتج',
  reconciliate: 'تصحيح',
  operation: 'عملية',
  oldNoOfBoxes: 'عدد العلب القديمة',
  newNoOfBoxes: 'عدد العلب الجديده',
  Surgeries: 'العمليات الجراحية',
  addNewImageCategory: 'إضافة نوع أشعة جديدة',
  prescriptions: 'الروشته',
  deleteSpecialty: 'حذف التخصص',
  tagName: 'اسم التاج',
  addSessionToDoctor: 'اضافة كشف للطبيب',
  feesCalculationType: 'نوع حساب الرسوم',
  feesCalculationMethod: 'طريقة احتساب الرسوم',
  newSession: 'جلسه جديده',
  deleteSession: 'حذف الكشف',
  insuranceDebit: 'Insurance Debit',
  cash: 'كاش',
  insuranceTransactions: 'معاملات التأمين',
  doctorSessions: 'جلسات الدكتور',
  gather: 'Gather',
  revert: 'أرجاع',
  addNewInsurance: 'إضافة معامله تأمين جديد',
  editInsurance: 'تعديل معامله تأمين ',
  totalAmount: 'الإجمالي',
  patientFees: 'رسوم المريض',
  paymentMethod: 'طريقة الدفع او السداد',
  cardExpiryDate: 'تاريخ انتهاء صلاحية البطاقة',
  refuse: 'رفض',
  doctorCourseParts: 'أجزاء الدورة ',
  payWithDoctorFees: 'دفع مصاريف الدكتور',
  editPaidWithDoctorFees: 'edit paid with doctor fees',
  deleteCoursePart: 'حذف جزء من الدورة',
  newCoursePart: ' إضافة جزء للدورة',
  addCoursePartToDoctor: 'أضافه جزء للدورة',
  part: 'جزء',
  payConsume: 'الدفع / الاستهلاك',
  cardNumber: 'رقم البطاقة',
  addNewFees: 'إضافة رسوم جديدة',
  amount: 'كمية',
  editFees: 'تعديل رسوم',
  newSessionDefination: 'تعريف كشف جديد',
  sessionPrice: 'سعر الكشف',
  numberOfUnits: 'عدد الوحدات',
  withDoctorFees: 'مع أتعاب الطبيب',
  withoutDoctorFees: 'بدون أتعاب الطبيب',
  remainingUnits: 'الوحدات المتبقية',
  totalUnits: 'إجمالي الوحدات',
  courseParts: 'أجزاء الكورس',
  internalExternal: 'داخلي / خارجي',
  extraUnits: 'وحدات اضافية',
  orderBy: 'ترتيب حسب',
  sellingPrice: 'سعر البيع',
  alertNumberOfBoxes: 'التذكير عند رقم ؟',
  sellable: 'قابل للبيع',
  unitSellingPrice: 'سعر بيع الوحدة',
  revenuesOrExpenses: 'الإيرادات / المصروفات',
  cashOrVisa: 'كاش / فيزا',
  cashAndVisa: ' كاش & فيزا',
  startTime: 'يبدأ من',
  endTime: 'ينتهي في',
  fromTo: 'من - إلي',
  model: 'نموذج',
  action: 'عمليه',
  insertAtLeast6numbers: 'أدخل 6 أرقام على الأقل',
  notComming: 'لن يأتي',
  patientId: 'رقم المريض',
  taxNumber: 'الرقم الضريبي',
  logging: 'سِجل كل العمليات',
  enableSMS: 'تفعيل الرسائل',
  SMSConfiguration: 'اعدادات الرسائل',
  followUp: 'متابعة',
  followUpFeatureOrNot: ' تفعيل المتابعة ؟',
  search: 'بحث',
  RoomDeviceName: 'الحُجرة أو الجِهاز ',
  external: 'خارجي',
  GuardiansName: 'اسم ولي الأمر',
  GuardiansPhoneNo: 'رقم هاتف ولي الأمر',
  cardId: 'رقم الكارت',
  totalOldUnits: 'مجموع الوحدات القديمة',
  gender: 'النوع',
  sessionReportSelector: 'اختار كشف او أكثر .لايزيدوا عن 10',
};
